import {
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerRoot,
  DrawerTitle,
  Group,
  MantineThemeOverride,
  createTheme,
  rem,
} from "@mantine/core"
import { Button } from "../Button/Button"
import { useTranslation } from "react-i18next"

import classes from "./DrawerKiosk.module.css"

export const themeDrawer: MantineThemeOverride = createTheme({
  components: {
    Drawer: {
      styles: {
        header: {
          padding: 24,
          borderBottom: `${rem(1)} solid var(--mantine-color-gray-2)`,
        },
        content: {
          height: "100%",
        },
        title: {
          fontSize: 18,
          fontWeight: 600,
        },
        body: {
          padding: `${rem(16)} ${rem(32)}`,
        },
      },
    },
    DrawerRoot: {
      defaultProps: { size: "lg", position: "right" },
    },
  },
})

type DrawerKioskProps = {
  children: JSX.Element
  opened: boolean
  onClose: () => void
  title: string
  primaryButtonLabel: string
  hasCancel?: boolean
  formName?: string
}

export const DrawerKiosk = ({
  children,
  opened,
  onClose,
  title,
  primaryButtonLabel,
  hasCancel,
  formName,
}: DrawerKioskProps) => {
  const { t } = useTranslation()

  return (
    <DrawerRoot opened={opened} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>{title}</DrawerTitle>
        </DrawerHeader>
        <DrawerBody>
          {children}
          <Group className={classes.drawer__footer}>
            {hasCancel && (
              <Button variant="gray" onClick={onClose}>
                {t("actions.cancel")}
              </Button>
            )}
            <Button type={formName ? "submit" : "button"} form={formName}>
              {primaryButtonLabel}
            </Button>
          </Group>
        </DrawerBody>
      </DrawerContent>
    </DrawerRoot>
  )
}
